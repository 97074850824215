<template>
  <v-card flat min-height="100vh">
    <Empty
      min-height="100vh"
      headline="System Status <br><br>On development"
    ></Empty>
  </v-card>
</template>

<script>
export default {}
</script>

<style></style>
